.root {
  color: #41485a;
  border: 1px solid #f1f3f9;
  background: rgba(233, 236, 245, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 20px 0;
}
.rootText {
  display: flex;
  flex-direction: column;
}
.heading {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: #2e2e36;
}
.subHeading {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #2e2e36;
  margin: 10px 0;
}
.uiCont {
  margin: 10px 0 0 !important;
  padding-left: 10px !important;
}
.list {
  font-weight: 400;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #2e2e36;
  list-style: disc !important;
}
