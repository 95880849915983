.formControlDate {
  width: 50%;
}
.fieldCnt {
  margin-bottom: 20px;
}
.fieldsGridClass {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 25px;
  column-gap: 50px;
}
.exludedWeekDays {
  width: 50%;
}
.seperator {
  border-bottom: 1px solid #ecebeb;
  margin: 30px 0;
}

.loaderCnt {
  position: relative;
  left: 50%;
  height: 500px;
}
.loaderStyles {
  margin-top: 20%;
}
