body {
  background-color: #f7f5f5 !important;
  font-family: "Open Sans", sans-serif !important;
}

.react-bootstrap-table {
  width: 100%;
  clear: both;
}

.react-bootstrap-table-pagination {
  width: 100%;
  clear: both;
  border-top: 1px solid #ecebeb;
  margin: 0;
}

.react-bootstrap-table-pagination-list {
  margin-top: 15px;
}

.react-bootstrap-table-pagination-list .pagination {
  float: right;
}

.react-bootstrap-table-pagination span {
  font-size: 14px;
  color: #bac2c8;
  font-weight: normal;
  margin-top: 15px;
  display: block;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #47ad73;
  border-color: #47ad73;
}

.menuSlideOpen a {
  color: #46ae73 !important;
  cursor: pointer;
}

.menuSlide a {
  cursor: pointer;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:disabled {
  background-color: #47ad73 !important;
  border-color: #47ad73 !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #47ad73 !important;
  border-color: #47ad73 !important;
}

.bttn a {
  color: #ffffff !important;
  cursor: pointer;
}

.close {
  cursor: pointer;
  font-weight: bold;
}

hr {
  border-top: 1px solid #e7e7e7;
}

.ck-editor__editable_inline {
  font-size: 12px;
}

#editor_jd .ck-editor__editable_inline {
  min-height: 250px;
  font-size: 12px;
}

.analytics1 {
  table-layout: fixed;
  word-wrap: break-word;
}

.analytics td.miantd {
  padding: 0;
}

.analytics td.miantd:first-child {
  width: 210px;
}

.analytics .table {
  margin-bottom: 0;
}

.analytics .listHeding th {
  text-align: center;
  text-transform: uppercase;
}

.innertable td {
  border-left: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.edit-form .react-datepicker-wrapper input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: 1px solid #e7e7e7;
  color: #323232;
  font-size: 14px;
  margin-bottom: 25px;
  background-color: #fafafa;
}

.edit-form .greyBox .react-datepicker-wrapper input {
  display: block;
  width: 100%;
  background-color: #f7f5f5;
}

a.primary,
a.primary:hover {
  margin-left: 10px;
  text-decoration: none;
}

.inactive {
  margin-left: 10px;
  color: #7e8081;
}

h2 a.primary {
  font-size: 13px;
  font-weight: bold;
}

.job_seeker .bttn a.disable {
  cursor: default;
  pointer-events: none;
}

.edit-form ul.kills {
  padding: 0;
  margin: 0;
  clear: both;
}

.edit-form ul.kills li {
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 8px;
}

.edit-form ul.kills li div {
  border: 1px solid #d3dfe2;
  text-decoration: none;
  color: #263838;
  padding: 5px 10px;
  background: #ffffff;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  display: inline-block;
  cursor: pointer;
}

.edit-form ul.kills li.active div {
  border: 1px solid #fdd493;
  background: #fff8ed;
  font-weight: 500;
}

.edit-form ul.kills li div span {
  background: url(./images/plusSign.png) no-repeat;
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 8px;
}

.edit-form ul.kills li.active div span {
  background: url(./images/checkSign.png) no-repeat;
  top: 0px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button.bttn {
  display: inline-block;
  background-color: #47ad73;
  color: #ffffff;
  font-size: 11px;
  text-decoration: none;
  padding: 9px 20px;
  margin-left: 10px;
  border: 1px solid;
}

button.bttn:disabled {
  background: #dddddd;
}

.edit_link {
  width: 50px;
}

.edit_link div {
  float: left;
}

.gap-20,
.gap-10 {
  clear: both;
}

th .select-filter {
  height: 24px;
  font-size: 11px;
  margin: 0px;
  padding: 0px;
  padding-left: 0px;
  padding-left: 3px;
}

.toggle-group h2 {
  padding: 0px;
  margin: 0px;
}

.multi-select .multiSelect {
  margin: 0 !important;
}

.job_seeker .multi-select {
  z-index: 9;
}

.job_seeker .multi-select .multiSelect .input-box {
  background-color: #f7f5f5 !important;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: 1px solid #ecebeb;
  font-size: 14px;
  border-radius: 0 !important;
}

.job_seeker .multi-select .multiSelect .chip-body {
  height: auto !important;
  background-color: #47ad73 !important;
}

.job_seeker .multi-select .multiSelect .search-result {
  background: #ffffff;
  position: absolute;
  z-index: 999;
  top: 42px;
  width: 94%;
}

.container input:checked ~ .checkmark {
  background-color: #47ad73 !important;
}

.multi-select label {
  position: absolute;
  display: block;
  top: 13px;
  left: 14px;
  z-index: 999;
}

.multi-select .input-box {
  position: absolute;
  display: block;
  top: 3px;
  left: 4px;
  z-index: 1;
}

.edit-form .pagination {
  font-size: 12px;
  display: flex;
}

.edit-form .pagination ul li {
  margin: 2px;
}

.edit-form .pagination ul li a {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.multi-select .css-yk16xz-control,
.multi-select .css-1pahdxg-control,
.multi-select .css-1s2u09g-control,
.multi-select .css-13cymwt-control {
  background-color: #f7f5f5;
  border: 0px;
  border-radius: 0;
  border-bottom: 1px solid #ecebeb;
}

.tagging th {
  width: 14%;
}

.tagging tr.others td {
  border-top: none;
  padding: 0.25em;
}

.tagging ul.kills li div {
  padding: 5px;
}

.tag-search {
  float: right;
  width: 90%;
}

.tag-search .form-control {
  margin: 0;
  padding-left: 0px;
}

.tag-search label {
  padding-top: 15px !important;
}

.searchTagResult {
  position: absolute;
  z-index: 99;
  background: #fff;
  border: 1px solid #f9f6f6;
  width: 85%;
  margin-top: -15px;
  margin-left: -15px;
}

.searchTagResult .result-list {
  cursor: pointer;
  margin: 4px;
  background-color: #f7f7f7;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: normal;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #dfe3e1;
  display: inline-block;
  padding: 3px 3px;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 10px !important;
}
.custom-file-upload-edit {
  display: inline-block;
  padding: 3px 3px;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 10px !important;
}
.custom-file-upload-edit img,
.custom-file-upload img {
  max-width: none !important;
}

.custom-file-upload .fa {
  color: #47ad73;
}

.red,
.redText {
  color: red;
}
.greenText {
  color: #47ad73;
}
.crawl input[type="number"] {
  -moz-appearance: none;
}

.search .row .to-col {
  color: #495057 !important;
}

.search .row .from-col {
  color: #495057 !important;
  padding-left: 30px !important;
  padding-top: 5px !important;
}

.pageHeading h2 a {
  margin-left: 10px;
}

.pageHeading .link {
  font-size: 13px;
  font-weight: bold;
  color: #47ad73;
  display: contents;
  cursor: pointer;
}

.rbt-input {
  margin-bottom: 0px !important;
}

b.downArrow {
  z-index: 1;
}

.dropdown-menu {
  font-size: 14px !important;
  color: #212529;
  text-align: left;
  list-style: none;
}

.edited {
  color: #007bff;
}

.form-control:disabled {
  cursor: not-allowed;
}

.query {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #dddddd;
}

.custom-pagination {
  width: 100%;
  clear: both;
  border-top: 1px solid #ecebeb;
  margin: 0;
}

.custom-pagination .pagination-count {
  color: #bac2c8;
  float: left;
  margin-top: 15px;
}

.custom-pagination .pagination {
  float: right;
  margin-top: 15px;
}

.custom-pagination .active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #47ad73;
  border-color: #47ad73;
  pointer-events: none;
}

.custom-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.custom-pagination .page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.custom-pagination ul li:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.custom-pagination ul li:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.bttn .verified {
  color: #47ad73;
}

.lead-download {
  margin-right: 10px;
  margin-top: 9px;
  display: inline-block;
}

.pageHeading .sperator {
  display: inline;
  color: #bac2c8;
  float: none;
  margin-left: 10px;
}

.job_seeker b.downArrow {
  z-index: 0;
  padding: 3px;
  margin-top: 13px;
}

.leadform b.downArrow {
  z-index: 0;
}

.download_link {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.sortingWrap .gray {
  background-color: #dddddd;
}

.sortingWrap .darkblue {
  background-color: #1313d2;
}

.statusCircle .gray {
  background-color: #dddddd;
}

.statusCircle .darkblue {
  background-color: #1313d2;
}

.pageHeading span.label {
  float: none;
  margin: 0 10px;
}

.unClickedChip {
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 8px 20px;
  border-radius: 20px;
  color: #263838;
}

.unClickedChip:hover {
  background-color: #47ad73;
  border: 1px solid #47ad73;
  color: #ffffff;
}
.unClickedChip a {
  color: #263838;
  text-decoration: none;
}
.unClickedChip a:hover {
  color: #ffffff !important;
}
.clickedChip {
  background-color: #47ad73;
  border: 1px solid #47ad73;
  color: #ffffff;
  pointer-events: none;
}

.disabledChip {
  pointer-events: none;
  background: #ecebeb;
}

iframe {
  padding: 0;
  margin: 0;
  border: none;
}

.open-new-window {
  display: flex;
  align-items: center;
  margin: 5px;
}

.open-new-window input[type="checkbox"] {
  margin-right: 5px;
}

.open-new-window label {
  margin: 0;
  cursor: pointer;
}

.timer {
  background-color: #d62222;
  color: #ffffff;
  padding: 0 5px;
  border-radius: 10px;
  font-weight: 700;
  display: block;
  text-align: center;
  max-width: 85px;
}

.green {
  background-color: #47ad73;
}

.blue {
  background-color: #8970bb;
}

.whiteCard h2 span a {
  color: #47ad73;
  font-weight: 600;
  text-decoration: none;
}

.summeryTable {
  border: 1px solid #dcdcdc;
  text-align: left;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.19);
  padding: 10px;
  font-size: 12px;
  border-left: 5px solid #47ad73;
}

.summeryTable strong {
  font-weight: 600;
}

.whiteCard textarea.form-control {
  height: 35px;
}

.organic .listHeding th:nth-child(5) {
  width: 100px;
}

.doc-status {
  text-transform: capitalize;
}

.listvalue a {
  text-decoration: none;
}

.custom-download {
  border: 1px solid #dfe3e1;
  padding: 1px 5px;
  cursor: pointer;
  float: right;
}

.client-btn {
  margin-top: 15px;
}

.client-btn button.bttn {
  padding: 10px;
}

.reported-job select.form-control {
  background-color: #ffffff !important;
  padding-left: 5px;
  border: 1px solid #ecebeb;
}

.reported-job textarea.form-control {
  background-color: #ffffff !important;
  padding-left: 5px;
  border: 1px solid #ecebeb;
}

.reported-job .listHeding th:nth-child(0) {
  width: 15%;
}

.reported-job .listHeding th:nth-child(1) {
  width: 15%;
}

.reported-job .listHeding th:nth-child(2) {
  width: 12%;
}

.reported-job .listHeding th:nth-child(3) {
  width: 15%;
}

.reported-job .listHeding th:nth-child(4) {
  width: 10%;
}

.reported-job .listHeding th:nth-child(5) {
  width: 20%;
}

.reported-job .listvalue td {
  vertical-align: top;
}

.reported-job .btn-success {
  padding: 3px;
  margin-top: 5px;
  background-color: #47ad73;
  border-color: #47ad73;
  margin-left: 5px;
}

.reported-job .custom-file-upload {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 10px !important;
}

.reported-job .file-name {
  margin-top: 5px;
}

.reported-job .text-danger {
  display: block;
  margin-top: 4px;
}

.reported-job .btn-danger {
  padding: 3px;
  margin-top: 5px;
  margin-left: 5px;
}

.reported-job .btn-secondary {
  padding: 3px;
  margin-top: 5px;
  margin-left: 5px;
}

.btn:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.reportedTable .listHeding th:nth-child(7) {
  width: 15%;
}

.reported-job td {
  position: relative;
}

.reported-job td .rightDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}

.reported-job td div.spinner {
  padding: 2px 0;
  margin-bottom: 5px;
  color: #495057;
}

.reported-job .listvalue:nth-child(2n + 1) {
  border-bottom: 1px solid #ecebeb;
  border-top: 1px solid #ecebeb;
}

.reported-job .button-container {
  display: flex;
  flex-direction: column;
}

.reported-job .actionButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2px 0;
  margin-bottom: 5px;
}

.reported-job td .title {
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.reported-job td b.downArrow {
  padding: 4px;
}

.reported-job .upload-file {
  float: left;
  margin-bottom: 0;
}

.reported-job .view-file {
  float: left;
}

.reported-job .view-file span {
  display: block;
}

.margin-bot-20 {
  margin-bottom: 20px;
}

.reported-job .created {
  display: block;
  color: #bac2c8;
  text-align: right;
  font-size: 10px;
}

.reported-job .remarks {
  color: #0056b3;
  margin: 4px;
  cursor: pointer;
}

.custome-tootip {
  padding: 5px 10px !important;
}

.reported-count {
  color: red;
  float: right;
  position: relative;
  top: -30px;
  cursor: pointer;
}

.marginTop {
  margin-top: 15px;
}

.alert-danger {
  color: #721c24 !important;
}

.auto_container {
  position: relative;
}

.suggestionsContainerOpen {
  max-height: 300px;
  overflow: auto;
  width: 100%;
  position: absolute;
  z-index: 999;
  border: 1px solid #e2e5ef;
  box-shadow: 0px 2px 7px rgba(2, 99, 188, 0.15);
  border-radius: 6px;
  background-color: #ffffff;
}

.suggestionsList {
  margin: 0;
  padding: 0px;
  list-style-type: "none";
}

.suggestion {
  display: "block";
  padding: 2px 10px;
  cursor: pointer;
}

.client {
  display: contents;
  margin-top: 2px;
}

.selected-client {
  background-color: rgb(230, 230, 230);
  border-radius: 2px;
  display: flex;
  margin: 3px 2px 1px 2px;
  min-width: 0px;
  box-sizing: border-box;
}

.clinet-name {
  border-radius: 2px;
  color: rgb(51, 51, 51);
  font-size: 85%;
  overflow: hidden;
  padding: 3px 3px 3px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.closeIcon {
  -moz-box-align: center;
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding: 0 4px !important;
  box-sizing: border-box;
  cursor: pointer;
}

.search-list-items {
  margin: 2px 0;
}

.recruiter-search b.downArrow {
  padding: 3px;
  margin-top: 13px;
}

.close-icon-container {
  position: absolute;
  right: 35px;
  top: 8px;
}

.clearIcon {
  border-right: 1px solid #adaeae !important;
  padding-right: 8px !important;
  color: #adaeae !important;
}

.rec-job-list .listHeding th:nth-child(2) {
  width: 150px;
}

.rec-job-list .listHeding th:nth-child(3) {
  width: 130px;
}

.rec-job-list .listHeding th:nth-child(4) {
  width: 120px;
}

.rec-job-list .listHeding th:nth-child(7) {
  width: 100px;
}

.rec-job-list .listHeding th:nth-child(8) {
  width: 135px;
}

.whiteCard h2 span.alert-success {
  color: #155724 !important;
}

.whiteCard h2 span.verified {
  color: #47ad73;
}

.summeryTable td.line {
  padding: 0px !important;
}

.summeryTable hr {
  margin: 0;
}

span.link {
  margin: 0px;
  cursor: pointer;
  color: #47ad73;
  font-weight: 700;
}

.verified .green {
  background-color: #47ad73;
  width: 13px;
  height: 13px;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-right: 5px;
}

.flag .reported-count {
  color: red;
  cursor: pointer;
  right: 10px;
  top: 0;
}

.crm-tab {
  display: inline-block;
}

.crm-tab ul {
  margin-left: 20px;
}

.crm-tab li {
  margin: 0;
  padding: 0;
  border: 1px solid #47ad73;
  border-radius: 4px;
  margin: 0 10px;
}

.crm-tab a.nav-link,
.crm-tab span.nav-link {
  font-size: 14px !important;
  margin: 0;
  padding: 5px 10px;
  color: #47ad73;
  cursor: pointer;
  font-weight: 700;
}

.crm-tab a.active,
.crm-tab span.active {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
  background-color: #47ad73;
  color: #ffffff;
}

.crm-tab a.nav-link:hover,
.crm-tab span.nav-link:hover {
  background-color: #47ad73;
  color: #ffffff;
}

.organic-tab {
  display: inline-block;
}

.organic-tab ul {
  margin-left: 20px;
}

.organic-tab li {
  margin: 0;
  padding: 0;
  border: 1px solid #47ad73;
  border-radius: 4px;
  margin: 0 10px;
}

.organic-tab a.nav-link {
  font-size: 14px;
  margin: 0;
  padding: 5px 10px;
}

.organic-tab a.nav-link:hover {
  background-color: #47ad73;
  color: #ffffff;
}

.organic-tab a.active {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
  background-color: #47ad73;
  color: #ffffff;
}

.unverified-recruiter .listHeding th:nth-child(1) {
  width: 7%;
}
.unverified-recruiter .listHeding th:nth-child(2) {
  width: 7%;
}
.unverified-recruiter .listHeding th:nth-child(3) {
  width: 10%;
}

.unverified-recruiter .listHeding th:nth-child(4) {
  width: 10%;
}

.unverified-recruiter .listHeding th:nth-child(8) {
  width: 20%;
}

.whiteCard h2 span.unVerified {
  color: red;
}

.unVerified .red {
  background-color: red;
  width: 13px;
  height: 13px;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-right: 5px;
}

.fraud-recruiter {
  position: sticky;
  overflow-x: auto;
  top: 0;
  z-index: 99999999;
  padding: 10px;
}

.fraud-recruiter-scroll {
  height: 160px;
}

.fraud-recruiter .item {
  padding: 2px 0px;
  font-size: 12px;
}

.fraud-recruiter h2 i {
  color: red;
  margin-right: 5px;
}

.fraud-recruiter h2 .float-right i {
  color: #47ad73;
  margin-right: 5px;
  cursor: pointer;
}

.fraud-recruiter ul {
  padding: 0 10px;
  margin: 0;
}

.fraud-recruiter ul > li {
  color: #47ad73;
}

.fraud-recruiter ul > li span {
  color: #212529;
}

.form-group .MuiFormControl-root {
  width: 100%;
}

.recruiter-search .form-group > div {
  width: 100%;
}

.recruiter-search .form-group > div > div {
  margin: -2px !important;
  max-height: 46px;
  overflow: auto;
}

.WAMuiChipInput-underline-14::after,
.WAMuiChipInput-underline-14::before {
  border-bottom: 1px solid #ecebeb !important;
}

.recruiter-search .form-group > div > label {
  top: -15px !important;
  color: #7e8081 !important;
  font-size: 14px !important;
  padding-left: 0.75rem !important;
}

.goback {
  position: absolute;
  top: 30px;
  right: 54px;
}

.goback a {
  font-size: 13px;
  font-weight: bold;
  color: #47ad73;
  vertical-align: inherit;
  margin: 0 20px;
}

.analytics table {
  font-family: "Lucida Console", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.analytics td:hover {
  background-color: #ddd;
}

.analytics .tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.analytics .td,
.analytics th {
  border: 1px solid #ddd;
  border-style: groove;
  padding: 8px;
  text-align: center;
}

.analytics .th {
  padding: 10px;
  background-color: #1a9db8;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.whiteCard textarea:read-only,
.form-control:disabled {
  color: #969696;
}

td i,
.audio-item i {
  margin-left: 10px;
}

td a.link,
td a.link:hover {
  margin-left: 0px;
  text-decoration: none;
}

audio {
  outline: none;
  display: block;
  background-color: transparent !important;
  -webkit-tap-highlight-color: transparent;
}

a.audio,
a.audio:hover {
  float: right;
  text-decoration: none;
  font-weight: 600;
}

.audio-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.whiteCard h2 span.not-repeat {
  color: #8970bb;
}

.not-repeat .blue {
  background-color: #8970bb;
  width: 13px;
  height: 13px;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-right: 5px;
}

.email-bounce {
  margin-top: 15px;
}

.company-details .alert {
  padding: 8px;
  font-size: 12px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.whitebox {
  min-height: 200px;
}

.whitebox h1 {
  width: 100%;
}

.reported-job .timerContainer {
  display: block;
  color: #bac2c8;
  text-align: right;
  font-size: 10px;
}

.reported-job .timerContainer .timer {
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  padding: 2px 5px;
}

.postjob {
  padding: 0px 4px;
}

.fraud-recruiter .toggleArrow {
  color: #47ad73;
  cursor: pointer;
  font-size: 20px;
  float: right;
}

.crawled-rec .selectBox {
  border-radius: 0 !important;
  padding-left: 5px;
  border: 1px solid #c4b8b8;
  color: #323232;
  margin: 0 0 5px 5px;
  background-color: #fafafa;
  max-width: 190px;
  min-width: 75px;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -2px;
  height: 25px;
}

.crawled-rec .selectBox2 {
  width: 190px;
}

.crawled-rec .postjob {
  font-size: 12px;
  float: right;
  margin: 4px 0 0 10px;
}

.marginL10 {
  margin-left: 10px;
}

.crawled-rec textarea.form-control {
  height: 30px;
  padding: 2px 0 0 5px;
  border: 1px solid #c4b8b8;
  font-size: 12px;
}
.crawled-rec input.form-control {
  padding-left: 5px;
  border: 1px solid #c4b8b8;
  color: #323232;
  margin: 0 0 5px 5px;
  background: transparent;
  height: 25px;
  font-size: 12px;
  max-width: 190px;
}
.crawled-rec .marginLeft0 {
  margin-left: 0 !important;
}

.date-lable {
  color: #495057 !important;
  padding-top: 5px;
  float: left;
}

.date-field {
  float: left;
  width: 180px;
  padding: 0 15px;
}

.date-time {
  font-size: 10px;
}

.padding15 {
  padding-left: 15px;
}

.list-lable {
  color: #495057 !important;
  padding-top: 2px;
  padding-left: 5px;
  width: 100%;
}

.edit-form .top-pagination .custom-pagination {
  border-bottom: 1px solid #ecebeb;
  width: 100%;
  border-top: none;
  display: inline-block;
}

.btn-close {
  position: absolute;
  right: 32px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn-close::before {
  content: "X";
  visibility: visible;
  font-weight: bold;
  opacity: 0.75;
  cursor: pointer;
}

.job_seeker .form-control {
  background-color: #f7f5f5 !important;
  border: none !important;
  border-bottom: 1px solid #ecebeb !important;
  font-size: 14px;
  border-radius: 0 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.rec-bucket {
  width: 100%;
  margin: 20px auto 0 auto;
}
.rec-bucket-center {
  justify-content: center;
}

.rec-bucket .box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ecebeb;
  margin: 20px 10px;
  text-align: center;
  color: #495057;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
  border-radius: 5px;
  font-weight: 600;
}
.rec-bucket-center .box {
  margin: 10px 8px;
}
.rec-bucket .box:hover {
  box-shadow: 0 3px 5px #969696;
}
.rec-bucket .active {
  border-bottom: 3px solid #47ad73;
}

.rec-bucket .box a {
  color: #495057;
  display: block;
  text-decoration: none;
  padding: 10px 20px;
}
.rec-bucket-center .box a {
  padding: 10px;
}
.recruiter-search .date-field {
  width: 170px;
}
.referral-link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  justify-content: flex-end;
}
.drawer-container {
  margin-top: 100px;
}
.drawer-container label {
  font-size: 11px;
  color: #7e8081;
  margin-bottom: 0;
  margin-right: 5px;
}

.drawer-container .saveBtn {
  font-size: 12px;
  float: right;
  margin: 4px 0 0 10px;
}
.job-tab {
  width: 100%;
  margin: 20px -20px;
}

.job-tab ul.tabs {
  padding: 0;
  margin: 0;
}

.job-tab ul.tabs li {
  display: inline-block;
  vertical-align: top;
  background: #ffffff;
  border: 1.5px solid #f0f2f8;
  box-sizing: border-box;
  box-shadow: 0px 4px 9px rgba(73, 102, 137, 0.2);
  border-radius: 100px;
  margin-right: 8px;
}

.job-tab ul.tabs li a {
  display: inline-block;
  font-size: 15px;
  color: #2e2e36;
  padding: 12px 20px;
  text-decoration: none;
  cursor: pointer;
}

.job-tab ul.tabs li.active {
  background: #ffe7e9;
  border: 1.5px solid #f8c5c8;
  font-weight: bold;
  pointer-events: none;
}

.edit-form .whiteCard {
  margin-bottom: 20px;
}

.view-history .view-log {
  font-size: 13px;
  font-weight: bold;
  color: #47ad73;
  cursor: pointer;
  display: inline;
}

.pageHeading .float-right {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.pageHeading .float-right span {
  display: flex;
}

.pageHeading .seperator {
  margin: 0 0 0 10px;
}

.whiteCard h2 span.underReview {
  color: #ffc107;
}

.underReview .yellow {
  background-color: #ffc107;
  width: 13px;
  height: 13px;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-right: 5px;
}

.required {
  color: red;
  font-weight: 700;
  font-size: 13px;
}

.job_seeker .download_link {
  margin: 0 20px;
  display: inline-block;
}

.scrollX {
  overflow-x: auto;
}
.whiteCard textarea.custom-texarea {
  height: 100px;
}
.add-referral-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.drawer-container .saveBtn {
  border-radius: 0.25rem;
}
.admin-panel-tabs {
  display: flex;
}

.admin-pannel .tabs,
.admin-panel-tabs .tabs {
  border-bottom: 1px solid #ecebeb;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}
.admin-panel-tabs .tabs {
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
}
.job-list .tabs {
  flex-direction: column;
  align-items: flex-start;
}
.admin-pannel .tabs .subtab,
.admin-panel-tabs .subtab {
  margin-top: 10px;
}
.admin-pannel .tabs .tab-list {
  display: flex;
  align-items: center;
  width: 70%;
}
.admin-pannel .tabs-cat {
  justify-content: space-between;
}

.admin-pannel .tabs .label,
.admin-panel-tabs .label {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  margin-top: 20px;
}
.admin-pannel .listHeding {
  background: #dcdcdc;
}
.admin-pannel .listHeding th {
  font-weight: 700 !important;
  width: 120px;
}
.admin-pannel .custom-pagination .listHeding {
  background: #ffffff;
}

.admin-pannel .listHeding th:nth-child(1) {
  width: 80px;
}
.admin-pannel .listHeding th:nth-child(2) {
  width: 180px;
}
.job-old-status-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
}
.whiteCard .oldStatusLabel {
  font-size: 14px;
  margin: 5px 0;
}
.moderator-list .form-control,
.moderator-list-cat .form-control {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #ecebeb !important;
  font-size: 14px;
  border-radius: 0 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  width: 100px;
  font-size: 12px !important;
  color: #7e8081 !important;
}
.moderator-list .listvalue td,
.moderator-list .listHeding th {
  padding: 8px 10px;
}
.moderator-list .listHeding th:nth-child(1) {
  width: 60px;
}
.moderator-list .listHeding th:nth-child(2) {
  width: 120px;
}
.moderator-list .listHeding th:nth-child(3) {
  width: 260px;
}
.moderator-list .listHeding th:nth-child(4) {
  width: 150px;
}
.moderator-list-cat .listHeading th:nth-child(1) {
  width: 60px;
}
.moderator-list-cat .listHeading th:nth-child(2) {
  width: 220px;
}
.moderator-list-cat .listHeading th:nth-child(3) {
  width: 100px;
}
.moderator-list-cat .listHeading th:nth-child(4) {
  width: 100px;
}
.moderator-list-cat .listHeading th:nth-child(5) {
  width: 150px;
}
.moderator-list-cat .listHeading th:nth-child(6) {
  width: 140px;
}
.cityGroup {
  height: 35px;
  width: 70%;
  padding: 5px;
  border: grey;
}
.moderator-list .form-control {
  padding: 4px 0;
}
.modAssignmentcontainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.modAssignmentcontainer .whitebox {
  margin: 0;
  padding: 20px !important;
}
.modAssignmentcontainer .float-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modAssignmentcontainer .btn {
  padding: 2px 10px;
  margin-left: 40px;
}
.inactiveChip {
  cursor: not-allowed !important;
  opacity: 0.4;
  pointer-events: none;
}
.edit-form ul.kills li.inactiveChip div {
  cursor: not-allowed !important;
}
.isAbsent {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100px;
  margin-left: 2px;
  background-color: #e06839;
}
.bittonLink {
  cursor: pointer;
  background: #ffffff;
  border: none;
  margin-right: 10px;
  color: #0056b3;
}
.urlcopyBox {
  border: 1px dashed #dcdcdc;
  padding: 4px 10px;
  margin-right: 10px;
}
.urlcopyBox a {
  margin-left: 5px;
}
.customFileClass {
  display: flex;
  margin-top: 6px;
}
.customFileClass .ducument-file-upload {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.customFileClass .ducument-file-upload span {
  margin-left: 5px;
}
.video-list .listHeding th:nth-child(5) {
  width: 300px;
}
.video-list .listHeding th:nth-child(6) {
  width: 200px;
}
.video-list .listHeding th:last-child {
  width: 150px;
}
.video-form ul.chips li.inactiveChip div {
  cursor: not-allowed !important;
  opacity: 0.6;
}
.video-form .disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
  pointer-events: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.clusterItem {
  display: flex;
  border-top: 1px solid #dcdcdc;
  margin-top: 10px;
  padding-top: 5px;
}
.clusterItem ul {
  padding: 0;
  margin: 0;
}
.clusterItem ul li {
  list-style: none;
}
.back-button {
  cursor: pointer;
  font-weight: 700;
  color: #47ad73;
}


.richJdBorder {
  border-color: #e9ecf5;
}
.textEditorBorder {
  border-color: #cbd5e1;
}
.basicH2Text {
  color: #414b5d;
}

.suggestedJdBackground {
  background-color: #f8faff;
}


.tiptap {
  padding: 0.875rem;
  width: 100%;
  height: 340px;
  overflow-y: scroll;
  font-size: 16px;
  line-height: 1.5rem;
}
.job-edit-container .tiptap ul li,.tiptap ul li {
  list-style-type: disc;
  /* padding-left: 0.5rem !important; */
  margin:0.25rem 1rem;
}

.tiptap ol {
  list-style-type: decimal;
  padding-left: 1rem;
}
.tiptap:focus {
  outline: none;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd; 
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
