.questioncnt {
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  position: relative;
  padding: 20px;
  width: 85%;
  margin: 0 auto 20px auto;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}
.questionTitleCnt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.formControlClassQue {
  width: 60%;
}
.formControlClassQueType {
  width: 30%;
}
.formNextQuestionId {
  width: 15%;
  margin: 0 20px !important;
}
.formPriorityField {
  width: 5%;
  margin: 0 10px !important;
}
.formControlClassOptions {
  width: 50%;
}
.addOption {
  color: #1a73e8;
  cursor: pointer;
}
.optionField {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.optionField i {
  margin: 0 25px;
  cursor: pointer;
}
.optionField i.disabled {
  pointer-events: none;
  cursor: copy;
}
.languageFormCnt {
  width: 100%;
}
.formCnt {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.fieldCnt {
  width: 93%;
}
.footerCnt {
  display: flex;
  border-top: 1px solid rgb(218, 220, 224);
  height: 44px;
  margin-top: 20px;
  align-items: baseline;
  justify-content: end;
  padding: 10px 20px 0px 20px;
}

.footerCnt i {
  margin: 0 10px;
  cursor: pointer;
}
.formActiveField {
  margin-bottom: 0px;
}
.formSwitch {
  margin-left: 0px !important;
}
.actionCnt {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  flex-direction: column;
  width: 68px;
  padding: 0 10px;
  display: flex;
}
.actionCnt i {
  font-size: 25px;
  margin: 10px 0px;
  cursor: pointer;
}
.actionCnt button {
  padding: 0;
  margin: 10px 0;
}
.accordionClass {
  margin-top: 20px;
}
.fieldCnt h4 {
  margin: 0 auto 10px auto;
  width: 85%;
  font-size: 1rem;
  color: grey;
}
.saveQueCnt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0;
}
.saveQueCnt span {
  margin-right: 10px;
}

.languageHeading {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: grey;
}
.languageHeading i {
  margin-left: 10px;
}
.duplicateDropdown {
  display: flex;
  justify-content: flex-end;
}
.helpTextCnt {
  margin-bottom: 20px;
}
.postTextCnt {
  column-gap: 12%;
  row-gap: 20%;
  display: grid;
  grid-template-columns: auto auto;
  margin: 40px 0 50px 0;
}
.saveDtlsButton {
  border-radius: 50%;
}
.saveDtlsButton:disabled {
  pointer-events: none;
}

.loaderCnt {
  position: relative;
  left: 50%;
  height: 500px;
}
.loaderStyles {
  margin-top: 20%;
}
