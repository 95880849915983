.campaignContainer {
  width: 100%;
  position: relative;
}
.leftConatiner {
  width: 77%;
  float: left;
}
.rightConatiner {
  float: right;
  width: 21%;
  background: #ffffff;
  border: 1px solid rgba(233, 236, 245, 0.6);
  border-radius: 10px;
  padding: 28px 28px 0 28px;
  margin-top: 0px;
}
.rightConatiner .infoContainer {
  margin: 20px 0 0 0 !important;
}
.rightConatiner .infoContainer .item {
  display: flex;
  float: left;
  border: 1px solid #f4f5f7;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #4e608a;
  margin: 0 6px 8px 0;
}
.rightConatiner .statusChip {
  display: flex;
}

.questioncnt {
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  padding: 20px;
  width: 85%;
  margin: 0 auto 20px auto;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}
.questionTitleCnt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.formControlClassQue {
  width: 60%;
}
.formControlClassQueType {
  width: 30%;
}
.formControlDate {
  width: 45%;
}
.addMore {
  color: #1a73e8;
  cursor: pointer;
}
.fullWidth {
  width: 100%;
}
.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.btnContainer span {
  margin: 0 10px;
}
.campaignTypeList {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.campaignTypeFormOption {
  width: 20%;
}
.campaignTypeFormTextbox {
  width: 40%;
  margin: auto 15px !important;
}
.campaignTypeFormImg {
  width: 30%;
}
.campaignTypeFormRemove {
  display: flex;
  width: 2%;
}
.campaignTypeFormRemove i {
  cursor: pointer;
}
.uploadImage {
  display: flex;
  flex-direction: column;
}
.uploadImage label {
  display: flex;
  align-items: center;
  margin: 0;
}
.uploadImage label img {
  cursor: pointer;
}
.uploadImage label span {
  margin-left: 10px;
  cursor: pointer;
}
.uploadImage div {
  display: flex;
  margin-top: 5px;
}
.accordionClass {
  margin-top: 20px;
}
.h2 {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #2e2e36;
}
.statusDiv {
  margin: 0 10px;
  display: inline-block;
  font-size: 18px;
}
.statusDiv .active {
  color: green;
}
.statusDiv .inactive {
  color: red;
}
.statusDiv .button {
  margin-left: 10px;
  background: #2a89f6;
  border-radius: 100px;
  padding: 5px 20px;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}
.statusDiv .button[disabled] {
  background-color: #84b3ea;
  pointer-events: none;
}
.loaderCnt {
  position: absolute;
  width: 100%;
  height: 2500px;
  background-color: #999999;
  z-index: 9999;
  opacity: 0.4;
}
.loaderStyles {
  top: 70%;
  position: relative;
  left: 50%;
  right: 50%;
}
.allowedLangCnt {
  width: 60%;
}
.titleCnt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.qualFormCnt {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.contentCnt {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  row-gap: 8px;
  margin: 15px 0;
}
.contentFieldCnt {
  display: flex;
  align-items: baseline;
}
.contentFieldCnt i {
  margin-left: 10px;
  cursor: pointer;
}
.addBenefits {
  color: #1a73e8;
  cursor: pointer;
  width: max-content;
}
.addMoreLangCnt {
  display: flex;
  justify-content: end;
}
.formFieldWidth45 {
  width: 45%;
}
