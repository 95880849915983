.submitBtnClass {
  margin: 0 !important;
}
.divider {
  margin: 40px 0;
  border-top: 1px solid gray;
}
.formElementsCnt {
  display: grid;
}
.dropdownCnt {
  padding: 10px 0 !important;
  width: 65%;
}
.cntClass {
  height: 750px;
}
.loaderCnt {
  position: relative;
  left: 50%;
  height: 500px;
}
.loaderStyles {
  margin-top: 20%;
}
