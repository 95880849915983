*:focus {
  outline: none !important;
}
/* width */
.job-edit-container ::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.job-edit-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.job-edit-container ::-webkit-scrollbar-thumb {
  background: #e2e5ef;
}

/* Handle on hover */
.job-edit-container ::-webkit-scrollbar-thumb:hover {
  background: #e2e5ef;
}
.job-edit-container ul {
  padding: 0;
  margin: 0;
}
.job-edit-container ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.job-edit-container {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #445578;
  width: 100%;
  background-color: #fdfdff;
  max-width: 1366px;
  min-height: 900px;
  margin: 0 auto;
  display: flex;
}
.main-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.header {
  height: 72px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 0px rgba(231, 234, 243, 0.6);
  padding: 0 48px;
  background: #ffffff;
}
.moderatorName {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #41485a;
  text-transform: capitalize;
}
.down-arrow {
  margin-left: 6px;
}
.form-container {
  padding: 38px 83px 0;
  position: relative;
}
.job-tab {
  margin: 22px 0 16px 0;
  display: flex;
  align-items: center;
}
.topSticky .job-tab {
  margin: 0;
}
.go-back {
  display: inline;
}
.go-back a {
  color: #445578;
  text-decoration: none;
}
.go-back a img {
  margin-right: 8px;
}
.rowContainer {
  display: flex;
  flex-direction: row;
}
.rowContainer .leftBar {
  display: flex;
  flex-direction: column;
  width: 67%;
  margin-right: 24px;
  position: relative;
}
.rowContainer .rightBar {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.containerCard {
  background: #ffffff;
  border: 1px solid #e9ecf5;
  box-shadow: 0px 4px 10px rgba(4, 29, 94, 0.06);
  border-radius: 10px;
  width: 100%;
  min-height: 400px;
}
.containerCard .company-details {
  padding: 34px 44px;
  max-height: 700px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #e2e5ef #e2e5ef;
}
.containerCard .company-details::-webkit-scrollbar {
  width: 3px;
  background: #ffffff;
  opacity: 0.2;
  border-radius: 100px;
  height: 62px;
}
.containerCard .company-details::-webkit-scrollbar-thumb {
  background-color: #e2e5ef;
}

.topSticky {
  position: sticky;
  overflow-x: auto;
  top: 0;
  left: 0;
  z-index: 99999999;
  padding: 10px;
  border-bottom: 1px solid #e9ecf5;
  box-shadow: 0px 4px 10px rgba(4, 29, 94, 0.06);
  padding: 14px 48px;
  margin: 0;
  display: flex;
  align-items: center;
  background: #ffffff;
}

.job-tab ul.tabs li {
  font-size: 15px;
  line-height: 22px;
  padding: 11px 16px;
  cursor: pointer;
  background: #ffffff;
  border: 1.5px solid #e9ecf5;
  box-shadow: none;
  border-radius: 100px;
  font-weight: 600;
}
.job-tab ul.tabs li:hover,
.job-tab ul.tabs li.active {
  background: #fff2f3;
  border: 1.5px solid #ffdbdd;
  box-shadow: -1px 2px 10px rgba(148, 0, 11, 0.1);
  color: #2e2e36;
}
.job-tab ul.tabs li img,
.job-tab ul.tabs li span {
  margin-left: 6px;
}

.topSticky .go-back a img {
  margin-right: 15px;
}
h2.heading {
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #2e2e36;
  margin-bottom: 32px;
}
h2.sideBarHeading {
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: #2e2e36;
  margin-bottom: 28px;
  margin-right: 6px;
}
h2.subHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2e2e36;
  margin-bottom: 28px;
  margin-right: 6px;
}
.label {
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 8px;
  display: block;
}
.optional {
  font-weight: 400;
  margin-left: 4px;
}
.rightSticky {
  position: fixed;
  top: 110px;
  z-index: 99;
  width: 384px;
}
.rfdContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(233, 236, 245, 0.3);
  border-radius: 10px;
  padding: 16px 28px;
}
.rfdContainer ul {
  padding: 0;
  margin: 0;
}
.rfdContainer ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: self-start;
  margin-bottom: 8px;
}
.rfdContainer ul li img.flag {
  margin: 2px 12px 0 0;
}
.rfdContainer ul li img.flagAutolive {
  margin: 0 8px 0 0;
}
.rfdContainer ul li .circle {
  background-color: black;
  width: 11px;
  height: 11px;
  margin: 2px 12px 0 0;
  border-radius: 100%;
}
.showHide {
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showHide span {
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.showHide span img {
  margin-left: 11px;
}
.formRow {
  display: flex;
  margin-bottom: 32px;
}
.formRow .left {
  width: 50%;
  float: left;
}
.formRow .right {
  width: 50%;
  float: right;
  margin-left: 24px;
}
.formRow .inputBox {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
}
.formRow .customInputClass {
  background: #ffffff;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2e2e36;
  border: none;
  height: 20px;
  width: 100%;
}

.formRow .verifiedEmail .customInputClass {
  width: 75%;
}

.formRow .customTextArea {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2e2e36;
  min-height: 46px;
}
.formRow .customTextArea::-webkit-scrollbar {
  width: 4px;
  background: #ffffff;
  opacity: 0.2;
  border-radius: 100px;
}
.formRow .customTextArea::-webkit-scrollbar-thumb {
  background-color: #e2e5ef;
}
.formRow .customInputClass::-webkit-input-placeholder,
.formRow .customTextArea::-webkit-input-placeholder {
  color: "#8D9DC1";
  font-weight: 400;
}

.formRow .customInputClass::placeholder,
.formRow .customTextArea::placeholder {
  color: "#8D9DC1";
  font-weight: 400;
}
.formRow textarea:focus,
.formRow input:focus {
  outline: none;
}
.formRow .customInputClass[readonly] {
  background: rgba(226, 229, 239, 0.2);
  color: #8d9dc1;
  width: 100%;
  padding: 0 16px;
  height: 44px;
}
.formRow .customTextArea[readonly] {
  background: rgba(226, 229, 239, 0.2);
  color: #8d9dc1;
  width: 100%;
}
.formRow .noPadding {
  padding: 0;
  width: 95%;
}
.leadForm .formRow .noPadding {
  padding: 0;
  width: 100%;
}
.formRow .inputBox .emailVerified {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #18c1a3;
  width: 70px;
}
.formRow .inputBox .emailVerified img {
  margin-right: 6px;
}
.formRow .inputBox .verifyEmail {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2a89f6;
  cursor: pointer;
  width: 115px;
}
.formRow .email-bounce {
  display: flex;
  align-items: center;
  margin-top: 9px;
}
.formRow .email-bounce img {
  margin-right: 6px;
}
.formRow .selectBox {
  display: flex;
  align-items: center;
}
.formRow .selectBox .select {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  background-image: url(./images/arrow_down.svg);
  background-position: right 16px center;
  background-repeat: no-repeat;
  border-radius: 6px;
  min-width: 150px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2e2e36;
  padding: 12px 16px;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.formRow .selectBox .select[disabled] {
  background: rgba(226, 229, 239, 0.2);
  color: #8d9dc1;
  border: 1px solid #e2e5ef;
  background-image: url(./images/arrow_down.svg);
  background-position: right 16px center;
  background-repeat: no-repeat;
}
.formRow .selectBox .selectActive {
  background-image: url(./images/arrow_up.svg);
}
.selectActive:hover::after {
  color: #23b499;
}
.formRow .fullWidth {
  width: 100%;
}

.formRow .selectBox .select::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #8d9dc1;
}

.formRow option:first-of-type {
  font-weight: 400;
  font-size: 15px;
  color: #8d9dc1;
}
.company-details .seperator {
  height: 1px;
  background: #e9ecf5;
  width: 100%;
  margin: 36px 0 48px 0;
}
.formRow .form-check-inline {
  margin-top: 9px;
}
.formRow .multi-select .multiSelect .input-box,
.formRow .selectBox .multi-select .multiSelect .input-box {
  background-color: #ffffff !important;
  border: 1px solid #e2e5ef;
  font-size: 14px;
  border-radius: 6px;
}
.formRow .multi-select .css-yk16xz-control,
.formRow .multi-select .css-b62m3t-container {
  background-color: #ffffff !important;
  border: 1px solid #e2e5ef;
  font-size: 14px;
  border-radius: 6px;
  padding: 4px 0px;
}
.formRow .multi-select .css-13cymwt-control {
  border: 0px solid !important;
}
.leadForm .selectBox .multi-select {
  width: 100%;
}
.leadForm .selectBox .multi-select .input-box {
  background-color: #ffffff !important;
  border: 1px solid #e2e5ef;
  font-size: 14px;
  border-radius: 6px;
  padding: 4px 0px;
}
.formRow .css-1okebmr-indicatorSeparator {
  display: none;
}
.formRow .css-tlfecz-indicatorContainer svg {
  color: #576381;
}
.formRow .customFileClass {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 44px;
}
.formRow .customFileClass .left {
  width: 95%;
}
.formRow .customFileClass .right {
  width: 5%;
}
.formRow .customFileClass a {
  font-weight: 600;
  font-size: 15px;
  color: #2a89f6;
  display: inline-block;
}
.formRow .customFileClass a {
  display: inline-block;
}
.formRow .customFileClass a img {
  margin-right: 6px;
}

.formRow .custom-file-upload {
  border: none;
  display: flex;
  cursor: pointer;
  margin: 0 !important;
  justify-content: flex-end;
  padding: 0;
}
.formRow .ducument-file-upload {
  cursor: pointer;
  color: #2a89f6;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.formRow .ducument-file-upload span {
  margin: 0 4px 0 12px;
}
.formRow a.reviewOnsite {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2a89f6;
  min-width: 115px;
  display: flex;
  align-items: center;
}
.formRow a.reviewOnsite img {
  margin-right: 6px;
}
/* start Chips*/
ul.chips {
  padding: 4px 0 0 0;
  margin: 0;
}
ul.chips li {
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 8px;
}
ul.disabled li {
  pointer-events: none;
}
ul.chips li div {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  border-radius: 100px;
  text-decoration: none;
  color: #2e2e36;
  font-size: 15px;
  padding: 8px 16px;
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
}

ul.chips li.active div {
  border: 1.5px solid #ffdbdd;
  background: #fff2f3;
  font-weight: 600;
}
ul.chips li div img {
  margin-left: 6px;
  cursor: pointer;
}
ul.chips li div span {
  background: url(./images/plus.png) no-repeat;
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 6px;
}

ul.chips li.active div span {
  background: url(./images/cross.png) no-repeat;
  top: 0px;
}

.statusContainer ul.chips li div {
  border-radius: 6px;
  pointer-events: none;
  cursor: pointer;
}
.jobStatusContainer .statusChip ul.chips li div {
  border-radius: 6px;
  padding: 8px 14px;
}
.jobStatusContainer .statusChip ul.chips li.active {
  pointer-events: none;
}
.jobStatusContainer .statusChip ul.chips li.active div {
  pointer-events: none;
  background: #ffdbdd;
  border: 1px solid #ffdbdd;
  color: #41485a;
}
.statusContainer ul.chips li.active div {
  pointer-events: none;
  background: #ffdbdd;
  border: 1px solid #ffdbdd;
}

.statusContainer .formRow,
.jobStatusContainer .formRow {
  margin-bottom: 28px;
}
.statusContainer .label,
.jobStatusContainer .label {
  font-size: 13px;
  line-height: 14px;
  display: flex;
}
/* End Chips*/

.btnContainer {
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 99;
  width: 100%;
  height: 66px;
  bottom: 0px;
  background: #ffffff;
  border: 1px solid #e9ecf5;
  box-shadow: 0px -6px 20px rgba(4, 29, 94, 0.06);
  padding: 0 44px;
  justify-content: flex-end;
  border-radius: 0 0 8px 8px;
}
.submitBtn {
  background: #2a89f6;
  box-shadow: -1px 2px 10px rgba(10, 89, 181, 0.1);
  border-radius: 100px;
  padding: 11px 20px;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}
.submitBtn:disabled,
.submitBtn[disabled] {
  background-color: #84b3ea;
  pointer-events: none;
}
.callingList {
  display: inline-block;
  margin-right: 35px;
}
.viewLogContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(233, 236, 245, 0.6);
  border-radius: 10px;
  padding: 14px 44px;
  margin: 20px 0 40px 0;
}
.viewLogContainer ul.active {
  display: flex;
  border-bottom: 1px solid #e9ecf5;
  padding-bottom: 13px;
}
.viewLogContainer ul li {
  margin-right: 24px;
  cursor: pointer;
  float: left;
}
.viewLogContainer ul li.active {
  padding-bottom: 13px;
  margin-bottom: -15px;
  border-bottom: 2px solid #ff6a6a;
}
.view-history {
  margin-top: 20px;
  width: 99%;
  overflow: auto;
  white-space: nowrap;
  max-height: 400px;
}
.recInfo {
  margin: -24px 0 36px 0;
}
.statusContainer,
.jobStatusContainer {
  background: #ffffff;
  border: 1px solid rgba(233, 236, 245, 0.6);
  border-radius: 10px;
  padding: 28px 28px 0 28px;
  margin-top: 20px;
}
.staticLabel {
  float: left;
  bottom: 30px;
  margin: 20px 0 0 28px;
}
.staticLabel .item {
  display: flex;
  float: left;
  border: 1px solid #f4f5f7;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #4e608a;
  margin: 0 6px 8px 0;
}
.staticLabel .item img {
  margin-left: 6px;
}
.staticLabel .item a {
  color: #2e2e36;
}
.successButton {
  background: rgba(24, 193, 163, 0.1);
  border-radius: 100px;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #18c1a3;
  padding: 11px 38px 13px 38px;
  border: none;
}
.successButton img {
  margin-right: 6px;
}
.btnContainer .errorMsg {
  color: red;
  margin: 0 20px;
}
.view-history .listHeding th {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #445578;
  text-transform: none;
  padding: 14px 20px 14px 0px;
}
.view-history .listvalue {
  background-color: #ffffff;
  border-top: 1px solid #e9ecf5;
}

.view-history .listvalue td,
.view-history .listvalue2 td {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #2e2e36;
  padding: 14px 20px 14px 0px;
  word-wrap: break-word;
}
.view-history .remarks {
  word-wrap: break-word;
  word-break: break-all;
  inline-size: 150px;
  white-space: pre-wrap;
}
.view-history .listvalue2 th {
  color: #8d9dc1;
  font-weight: 400;
}
.view-history .listvalue:nth-child(odd) {
  background-color: #ffffff;
}
.statusBtnContainer {
  width: 100%;
}
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.statusBtnContainer .successButton {
  width: 100%;
  padding: 16px 20px;
  pointer-events: none;
}
.statusBtnContainer .submitBtn {
  width: 100%;
  padding: 16px 20px;
  animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  will-change: opacity;
}

.topContainer {
  display: flex;
  width: 67%;
  padding-right: 24px;
}
.topContainerLead {
  display: flex;
  width: 67%;
  padding-right: 24px;
  margin-top: 20px;
}

.leadsLoader {
  width: 100%;
  height: 50px !important;
}

.leadsLoader2 {
  width: 50%;
  height: 50px !important;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 22px 0 16px 0;
}
.paginationContainer .next,
.paginationContainer .prev {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  border-radius: 100px;
  padding: 6px;
  cursor: pointer;
  height: 32px;
  width: 32px;
}
.paginationContainer .item {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  box-shadow: 0px 2px 8px rgba(11, 33, 115, 0.07);
  border-radius: 16px;
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2e2e36;
}

.paginationContainer .active {
  cursor: pointer;
  font-weight: 600;
}

.formRow .dateItem {
  float: left;
}
.formRow .dateTo {
  float: left;
  margin: 30px 14px 0 14px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
.formRow .jdTo {
  float: left;
  margin: 14px 14px 0 14px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
.formRow .react-datepicker-wrapper input {
  background: #ffffff;
  border: 1px solid #e2e5ef;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 150px;
}
.css-1uccc91-singleValue,
.css-1wa3eu0-placeholder {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2e2e36;
}
.towColumns .dateItem .inputBox {
  width: 120px;
}
.towColumns .salary .dateItem .inputBox {
  width: 90px;
}
.towColumns
  .dateItem
  .inputBox
  input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
  padding-left: 10px;
}
.towColumns .selectBox .select,
.towColumns .selectBox .selectActive {
  min-width: 120px;
}
.rupee {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #445578;
  margin-right: 12px;
}
.addEnglish {
  color: #2a89f6;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.addEnglish img {
  margin-right: 4px;
}
.leftBar .div-loader {
  margin-top: 50px;
  font-size: 14px;
  font-weight: 500;
}
.rightBar .div-loader {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}
.callingList ul.chips li.active div {
  pointer-events: none;
}
.callingList ul.chips li.active div img {
  pointer-events: all;
}
.editedField {
  background: #f1e0bf;
  border-radius: 100px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #ffa800;
  padding: 2px 8px;
  margin-left: 6px;
}
.paginationContainer .dropdown .btn-success {
  background: #ffffff !important;
  border: 1px solid #e2e5ef !important;
  box-shadow: 0px 2px 8px rgb(11 33 115 / 7%);
  border-radius: 16px !important;
  width: 80px;
  text-align: center;
  padding: 8px 12px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: 600;
  color: #2e2e36 !important;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  margin: 0 8px;
}
.paginationContainer .dropdown .dropdown-toggle::after {
  display: none !important;
}
.paginationContainer .dropdown-menu.show {
  display: block;
  min-width: 90px;
  border-radius: 16px;
  margin-top: -36px;
  min-width: 80px;
  overflow: auto;
  max-height: 300px;
}
.paginationContainer .dropdown-menu.show .dropdown-item {
  padding: 8px 12px !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2e2e36;
}
.paginationContainer .dropdown-menu.show::-webkit-scrollbar {
  width: 3px;
  background: #ffffff;
  opacity: 0.2;
  border-radius: 100px;
  margin: 30px 0;
}
.paginationContainer .dropdown-menu.show::-webkit-scrollbar-thumb {
  background-color: transparent;
}
::-webkit-scrollbar-track {
  margin-block: 15px;
}

.oldHistory {
  border-top: 1px solid #e9ecf5;
}
.tableContainer {
  margin-left: 100px;
}
.tableContainer .listvalue2 {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #8d9dc1;
}
.tableContainer .listvalue2 th {
  color: #8d9dc1;
  width: 140px;
  padding: 14px 0;
  font-weight: 400;
}
.tableContainer .listvalue2 td {
  color: #2e2e36;
  padding: 14px 0;
  font-weight: 600;
}
.editedContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 11px 12px 19px 12px;
}
.editedContainer img {
  margin: 0 8px;
}
.suggestedJdItem {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.suggestedJdItem .form-check-input {
  margin-top: 2px;
}
.job-edit-container ul li.inactiveChip {
  cursor: not-allowed !important;
  opacity: 0.6;
}
.job-edit-container ul li.inactiveChip div {
  cursor: not-allowed !important;
}
.towColumns .dateItem .inputBox {
  width: 126px;
  padding: 12px;
}
.statusContainer .label span {
  margin-left: 4px;
}
.formRow .client-list {
  margin-bottom: 55px !important;
  width: 100%;
}

.editedContainer .item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2e2e36;
}

.editedContainer .active {
  cursor: pointer;
  font-weight: 600;
}
.editedContainer .dropdown .btn-success {
  background: #ffffff !important;
  border: none !important;
  box-shadow: none;
  border-radius: 0 !important;
  text-align: center;
  padding: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: 400;
  color: #2e2e36 !important;
  cursor: pointer;
  margin: 0;
}
.editedContainer .dropdown .dropdown-toggle::after {
  border: none !important;
  content: url(./images/arrow_down.svg);
  margin-left: 8px;
}
.editedContainer .dropdown-item {
  padding: 5px 16px;
  pointer-events: none;
}
.staticLabel .autolive {
  color: #18c1a3;
  background: rgba(24, 193, 163, 0.1);
  border: none;
}
.viewmore {
  margin-left: 4px;
  color: #18c1a3;
  cursor: pointer;
}

.vmContainer {
  display: flex;
  width: 100%;
  background: rgba(233, 236, 245, 0.3);
  border-radius: 10px;
  padding: 16px 28px;
  margin-bottom: 20px;
  align-items: center;
}
.vmContainer .left {
  width: 90%;
  display: flex;
}
.vmContainer .col1 {
  margin-right: 5px;
}
.vmContainer .col2 {
  font-weight: 700;
  border-left: 1px solid #41485a;
  padding-left: 6px;
}
.vmContainer .col3 {
  display: flex;
  justify-content: flex-end;
  width: 10%;
  position: relative;
}
.vmContainer .col3 img {
  cursor: pointer;
}

.vmContainer .title {
  font-weight: 600;
  margin-bottom: 4px;
}
.vmContainer .info {
  color: #7c8ba2;
}
.vmContainer .info strong {
  font-weight: 600;
}
.formRow .editLink {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2a89f6;
  cursor: pointer;
  width: 40px;
  background: rgba(226, 229, 239, 0.2);
  height: 44px;
  align-items: center;
  display: flex;
}
.noActionChip {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.noActionChip .item {
  margin: 2px 0;
  width: 50%;
}
.leadFormRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.leadFormRow .left,
.leadFormRow .right {
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
}
.leadFormRow .right {
  width: 47%;
}
/*
* Responsive UI
*/

@media (max-width: 575.98px) {
  .job-edit-container {
    width: 100%;
  }
  .header {
    padding: 0 24px;
  }
  .form-container {
    padding: 24px;
  }
  .topcontainer {
    width: 100%;
  }
  .rowContainer {
    flex-direction: column;
  }
  .rowContainer .leftBar,
  .rowContainer .rightBar {
    width: 100%;
    margin: 0;
  }
  .rowContainer .rightBar {
    width: 100%;
    margin-top: 20px;
  }
  .containerCard .company-details {
    overflow: unset;
    padding: 24px 16px;
    max-height: none;
  }
  .rfdContainer {
    padding: 16px;
  }
  .statusContainer,
  .jobStatusContainer,
  .viewLogContainer {
    padding: 16px;
  }
  .staticLabel {
    bottom: 30px;
    margin: 0;
    padding: 20px 0;
  }
  .btnContainer {
    padding: 0 16px;
  }
  .topSticky {
    padding: 14px 0 14px 8px;
  }
  .topSticky .go-back a img {
    margin-right: 8px;
  }
  .topContainer {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .job-tab {
    margin: 10px 0 0 0;
  }
  .job-tab ul.tabs li {
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .paginationContainer {
    margin: 12px 0;
  }
  .callingList {
    margin: 0;
  }
  .company-details .seperator {
    margin: 20px 0;
  }
  .formRow {
    margin-bottom: 10px;
    flex-direction: column;
  }
  .formRow .right,
  .formRow .left {
    width: 100%;
    margin: 10px 0 10px 0;
  }
  .towColumns .dateItem .inputBox {
    width: 110px;
  }
  .jobStatusContainer ul.chips li div {
    padding: 8px 12px;
  }
  .statusContainer .formRow,
  .jobStatusContainer .formRow {
    margin-bottom: 16px;
  }
  .formRow .react-datepicker-wrapper input {
    width: 110px;
  }
  .tableContainer {
    margin-left: 0px;
  }
  .formRow .inputBox {
    padding: 12px;
  }
  .margin-bottom-title {
    margin-bottom: 65px !important ;
  }
  .vmcontainer {
    padding: 16px;
  }
  .noActionChip .item {
    width: 100%;
  }
}
.formRow .selectBox .borderRed,
.formRow .borderRed {
  border: 1px solid #f2bfbe;
  border-radius: 6px;
}
.recDetails {
  color: #445578;
  font-weight: 600;
  line-height: 20px;
}
.recDetails span {
  font-weight: 400;
}
.warningContainer {
  width: 100%;
  display: flex;
}
.warningContainer div {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}
.warningContainer div img {
  margin-right: 5px;
}

.premiumIntent ul li > div {
  border-radius: 100px !important;
  cursor: pointer;
}

.premiumIntent ul li {
  cursor: pointer;
}

.job-archived {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ef5757;
  font-size: 15px;
  font-weight: 600;
  height: 49px;
}
.paidStatus {
  background-color: #47ad73;
  color: #ffffff;
  height: fit-content;
  display: "flex";
  top: 3px;
  left: 3px;
  padding: 4px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  width: 40px;
  display: flex;
  justify-content: center;
  line-height: 13px;
  letter-spacing: 0.4px;
  position: relative;
}
.archiveContainer {
  background-color: #fff3de;
  display: flex;
  justify-content: center;
}
.archiveContainer img {
  margin-right: 8px;
}
.assinedTo {
  font-weight: 600;
  margin: 0 5px;
}
.highlitedNewCity {
  background-color: #18c1a3;
  color: #ffffff;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  margin-left: 10px;
}
.restrictedCityCatLine {
  color: red;
}

.expiringCredits {
  padding: 6px 6px;
  background-color: #ffb6c1;
  position: relative;
  top: -26px;
  text-align: center;
  font-weight: 600;
}

.separate {
  border-bottom: 1px dotted;
  padding-bottom: 4px;
  margin-bottom: 4px;
}
.assign-to .formRow .customInputClass {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}
.assign-to .formRow .customInputClass::placeholder {
  color: #495057;
  font-weight: 400;
}
.rejectedButton {
  color: #dd4840;
  background: #ffe5e4;
}
.disabledButton {
  color: #414b5d;
  background: #e9ecf5;
}
