.content {
  position: relative;
  z-index: 1;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop .text-center {
  color: white;
}

.loaderCnt {
  position: relative;
  left: 50%;
  height: 500px;
}
.loaderStyles {
  margin-top: 20%;
}
