.ruleFormCnt {
  width: 100%;
  margin: auto auto auto 0px;
}

.card {
  margin: 15px 0px;
}

.ruleHeading {
  margin: 0 0px 10px 0px;
  font-size: 1rem;
  color: grey;
}
.formHeader {
  display: flex;
  align-items: baseline;
}
.formHeader i{
  font-size: 1.1rem;
}

.allowedLeadtypeField {
  width: 60%;
}

.formSwitch{
  margin-left: 0px !important;
}
