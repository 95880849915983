/* Backdrop.css */
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: baseline;
  z-index: 1;
}

.backdropContent {
  padding: 10px;
}
